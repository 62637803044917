<template>
   <v-form ref="member-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Member detail</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>
         
         <!-- <v-col cols="12">
            <v-dialog  max-width="600" >
               <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" color="primary" class="m-auto px-8" large :disabled="isFetching">Reset Password</v-btn>
               </template>
               <template v-slot:default="dialog">
                  <v-form ref="member-password-form" @submit.prevent="passwordFormSubmit">
                     <v-card class="py-3">
                        <v-card-text>
                           <v-row>
                           <v-col md="12" cols="12">
                                 <FormControl inputType="string" v-model="data.newPassword" label="New password" :required="true"/>
                              </v-col>
                           </v-row>
                        </v-card-text>
                        <v-card-text class="d-flex flex-column justify-center align-center">
                           <v-row class="mt-2">
                              <v-col cols="6">
                                 <v-btn color="secondary" class="m-auto white--text form-btn" x-large @click.prevent="dialog.value=false">Cancel</v-btn>
                              </v-col>
                              <v-col cols="6">
                                 <v-btn color="primary" class="px-8 form-btn" x-large :disabled="isFetching" type="submit">Save</v-btn>
                              </v-col>
                           </v-row>
                        </v-card-text>
                     </v-card>
                  </v-form>
               </template>
            </v-dialog>
         </v-col> -->

         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="select" v-model="data.type" label="Account Type" :required="false" :options="dataOptions.type" :readonly="true"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="email" v-model="data.email" label="Email" :required="false" :readonly="true"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.referral_code" label="Referral code" :required="false" :readonly="true"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.credit" label="Credit" :required="false" :readonly="true"/>
                     </v-col>

                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.username" label="Username" :required="false"/>
                     </v-col>
 
                     <v-col md="6" cols="12">
                        <FormControl inputType="phone" v-model="data.phone" label="telephone" :required="false"/>
                     </v-col>

                     <v-col md="6" cols="12">
                        <FormControl inputType="select" v-model="data.level" label="User level" :required="false" :options="dataOptions.userLevel"/>
                     </v-col>

                     <v-col md="6" cols="12" v-if="data.type === 'technician'">
                        <ImageBtnUpload inputType="string" v-model="data.user_photo" label="Photo" :required="false"/>
                     </v-col>
                     <v-col md="6" cols="12" v-if="data.type === 'technician'">
                        <ImageBtnUpload inputType="string" v-model="data.hkid" label="HKID" :required="false"/>
                     </v-col>
                     <v-col md="6" cols="12" v-if="data.type === 'technician'">
                        <ImageBtnUpload inputType="string" v-model="data.residenceProof" label="Residence proof" :required="false"/>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>

         <!-- commercial -->
         <v-col cols="12" v-if="data.type === 'commercial'">
            <v-card>
               <v-card-title><h5>Company</h5></v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.company.name" label="Name" :required="false"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="select" v-model="data.company.business.type" label="Business type" :required="false" :options="dataOptions.businessType"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.company.business.registration" label="Business registration" :required="false"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <ImageBtnUpload inputType="string" v-model="data.company.business.photo" label="Business registration photo" :required="false"/>
                     </v-col>
                     <v-col md="12" cols="12">
                        <FormControl inputType="string" v-model="data.company.address" label="Address" :required="false"/>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         
         <!-- technician -->
         <v-col cols="12" v-if="data.type === 'technician'">
            <v-card>
               <v-card-title><h5>Experience</h5></v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col md="12" cols="12">
                        <FormControl inputType="string" v-model="data.skills" label="Skills" :required="false"/>
                     </v-col>
                  </v-row>
               </v-card-text>
               <v-card-text class="text-center" v-show="data.experiences.length === 0">No Experience</v-card-text>
               <v-card-text>
                  <v-row v-for="(experience, index) in data.experiences" :key="`experience-${index}`">
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="experience.start" label="Start date" :required="false"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="experience.end" label="End date" :required="false"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="experience.title" label="Position" :required="false"/>
                     </v-col>
                     <v-col md="12" cols="12">
                        <FormControl inputType="textarea" v-model="experience.description" label="Description" :rows="8" :required="false"/>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>

         <!-- voucher -->
         <v-col cols="12" v-if="data.type !== 'technician'">
            <v-card>
               <v-card-title><h5>Voucher</h5></v-card-title>
               <v-card-text class="text-center" v-show="data.vouchers.length === 0">No Voucher</v-card-text>
               <v-card-text v-for="(voucher, index) in data.vouchers" :key="`voucher-${index}`">
                  <v-row>
                     <v-col md="4" cols="12">
                        <FormControl inputType="string" v-model="voucher.order_id" label="Order ID" :required="false" :readonly="true"/>
                     </v-col>
                     <v-col md="4" cols="12">
                        <FormControl inputType="select" v-model="voucher.type" label="Voucher type" :required="false" :options="dataOptions.voucherType" :readonly="true"/>
                     </v-col>
                     <v-col md="4" cols="12">
                        <FormControl inputType="select" v-model="voucher.used" :options="dataOptions.used" :required="false" :readonly="true"/>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>

         <v-col cols="12">
            <!-- <v-btn color="purple darken-3 white--text" class="ma-2 mx-3 m-auto report-btn" large href="" target="_blank" :disabled="isFetching">Trade-in record</v-btn>
            <v-btn color="purple darken-3 white--text" class="ma-2 mx-3 m-auto report-btn" large href="" target="_blank" :disabled="isFetching">Purchase record</v-btn>
            <v-btn color="purple darken-3 white--text" class="ma-2 mx-3 m-auto report-btn" large href="" target="_blank" :disabled="isFetching">Appointment record</v-btn> -->
            <FormButtonQRCode :url="data.qrcode" />
         </v-col>

         <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <!-- <FormDeleteBtn :removeAction="removeItem" v-if="!isNew" /> -->
         </v-col>

         <!-- <v-col cols="12">
            <v-row no-gutters>
               <v-btn color="primary" class="form-btn ma-2 m-auto px-8" large :disabled="isFetching" type="submit">Save</v-btn>
               <FormDeleteBtn :removeAction="removeItem" v-if="id != undefined" />
            </v-row>
         </v-col> -->

      </v-row>
   </v-form>
</template>

<script>
import FormButtonQRCode from '@/components/form/FormButtonQRCode'
import FormButtonUpload from '@/components/form/FormButtonUpload'
import FormControl from '@/components/form/FormControl'
import FormDeleteBtn from '@/components/form/FormDeleteBtn'
import FormDisplay from '@/components/form/FormDisplay'
import ImageBtnUpload from '@/components/form/ImageBtnUpload'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
   name: 'MemberDetail',
   components: {
      FormDisplay,
      FormControl,
      FormButtonUpload,
      FormDeleteBtn,
      FormButtonQRCode,
      ImageBtnUpload
   },
   data() {
      return {
         data: {
            id: _.toNumber(this.$route.params.id),
            username: '',
            email: '',
            phone: '',
            referral_code: '',
            type: '',
            level: '',
            qrcode: '',
            credit: 0,
            // commercial
            company: {
               name: '',
               address: '',
               business: {
                  type: '',
                  registration: '',
                  photo: '',
               }
            },
            // technician
            user_photo: '',
            hkid: '',
            residenceProof:  '',
            experiences: [],
            skills: '',
            // voucher
            vouchers: [],
         },
         dataOptions: {
            type: [
               { value: 'normal', text: 'Normal' },
               { value: 'commercial', text: 'Commercial' },
               { value: 'technician', text: 'Technician' },
            ],
            userLevel: [
               { value: 'normal', text: 'Normal' },
               { value: 'vip', text: 'Vip' },
               { value: 'strategic', text: 'Strategic' },
            ],
            businessType: [
               { value: 'banking', text: 'Banking' },
               { value: 'engineering', text: 'Engineering' },
               { value: 'it', text: 'IT' },
            ],
            used: [
               { value: true, text: 'Used' },
               { value: false, text: 'Not used' },
            ],
            voucherType: [
               { text: '硬件檢查服務', value: 'hardware' },
               { text: '電腦維修服務', value: 'repair' },
               { text: '電腦螢幕檢查', value: 'screen' },
            ],
         },
         qrCodeBtn: false,
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),
      
      async initData() {
         // const resp = await this.sendRequest({ type: 'get_user_by_id', data: { id: this.data.id } })
         const [userResp, creditResp, voucherResp] = await Promise.all([
            this.sendRequest({ type: 'get_user_by_id', data: { id: this.data.id } }),
            this.sendRequest({ type: 'get_user_credit', data: { user_id: this.data.id } }),
            this.sendRequest({ type: 'get_user_voucher', data: { user_id: this.data.id } }),
         ])
         console.log('initData', userResp, creditResp, voucherResp)
         if (!userResp || !creditResp || !voucherResp) return
         this.data = {
            ...this.data,
            username: userResp.data.username,
            email: userResp.data.email,
            phone: userResp.data.phone,
            referral_code: userResp.data.referral_code,
            type: userResp.data.user_type,
            level: userResp.data.user_level,
            qrcode: this.$getMediaUrl(userResp.data.user_qrcode),
            credit: creditResp.data.amount,
            // commercial
            company: {
               name: userResp.data.company_name,
               address: userResp.data.company_address,
               business: {
                  type: userResp.data.business_type,
                  registration: userResp.data.br,
                  photo: userResp.data.br_file,
               }
            },
            // technician
            user_photo: userResp.data.user_photo,
            hkid: userResp.data.hkid_copy,
            residenceProof: userResp.data.residence_proof,
            experiences: _.map(userResp.data.work_exp, (exp) => ({
               ...exp,
               start: moment(exp.start).format('YYYY-MM-DD'),
               end: moment(exp.end).format('YYYY-MM-DD'),
            })),
            skills: userResp.data.skills,
            // voucher
            vouchers: voucherResp.data,
         }
      },
      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['member-form'].validate()
         if (isValid) {
            const resp = await this.updateItem()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp, 
               returnLink: (this.data.id === undefined ? { name: 'member' } : null )
            })
            this.setShowDialog(true)
         }
      },
      async updateItem() {
         console.log('update')
         let requestType = ''
         let formdata = {
            data_type: 'user_data',
            id: this.data.id,
            username: this.data.username,
            phone: this.data.phone,
            user_level: this.data.level,
         }
         switch (this.data.type) {
            case 'commercial':
               requestType = 'update_commercial'
               formdata = {
                  ...formdata,
                  company_name: this.data.company.name,
                  company_address: this.data.company.address,
                  business_type: this.data.company.business.type,
                  br: this.data.company.business.registration,
                  br_file: this.data.company.business.photo,
               }
               break;
            case 'technician':
               requestType = 'update_technician'
               formdata = {
                  ...formdata,
                  user_photo: this.data.user_photo,
                  hkid_copy: this.data.hkid,
                  residence_proof: this.data.residenceProof,
                  work_exp: this.data.experiences,
                  skills: this.data.skills,
               }
               break
            case 'normal':
            default:
               requestType = 'update_user'
         }
         return await this.sendRequest({ type: requestType, data: formdata })
      },
      // async passwordFormSubmit() {
      //    console.log('save password')
      //    const isValid = this.$refs['member-password-form'].validate()
      //    if (isValid) {
      //       const formdata = {
      //          email: this.data.email,
      //          password: this.data.newPassword,
      //       }
      //       const resp = await this.sendRequest({ type: 'reset_password', data: formdata })
      //       // const resp = await this.sendRequest({ data: formdata })
      //       this.setDialogMessage({ 
      //          message: resp ? 'Successful' : 'Failed', 
      //          isError: !resp, 
      //          returnLink: (this.data.id === undefined ? { name: 'member' } : null )
      //       })
      //       this.setShowDialog(true)
      //    }
      // },
   },
   async mounted() {
      await this.initData()
      console.log(this.data)
   }
}
</script>
